// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-books-js": () => import("./../src/pages/books.js" /* webpackChunkName: "component---src-pages-books-js" */),
  "component---src-pages-business-js": () => import("./../src/pages/business.js" /* webpackChunkName: "component---src-pages-business-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-know-their-names-js": () => import("./../src/pages/know-their-names.js" /* webpackChunkName: "component---src-pages-know-their-names-js" */),
  "component---src-pages-podcasts-js": () => import("./../src/pages/podcasts.js" /* webpackChunkName: "component---src-pages-podcasts-js" */),
  "component---src-pages-videos-js": () => import("./../src/pages/videos.js" /* webpackChunkName: "component---src-pages-videos-js" */),
  "component---src-templates-details-js": () => import("./../src/templates/details.js" /* webpackChunkName: "component---src-templates-details-js" */)
}

